import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="container">
      <div class="row">
        <div class="col p-5">
          <h1>404 NOT FOUND</h1>
          <p>You just hit a page that doesn&#39;t exist :(</p>
          <a href="/">Go to Homepage</a>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
